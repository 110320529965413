import React from 'react';
import ReactDOM from 'react-dom';
import './app.scss';
import {Provider} from 'react-redux';
import Main from './components/Main';
import {BrowserRouter as Router} from 'react-router-dom';
import initialState from './reducers/initialState';
import store from './store/store';
class App extends React.Component {
    constructor(props) {
        super(props);
        if (process.env.NODE_ENV === 'production') {
            console.log('Looks like we are in production mode!');
        }
    }

    render() {
        return (
            <Provider store={store(initialState)}>
                <Router>
                    <Main />
                </Router>
            </Provider>
        );
    }
}

ReactDOM.render(<App />, document.getElementById('root'));
