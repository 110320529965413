import img1 from './assets/images/totius-bild-1.jpg';
import img2 from './assets/images/totius-bild-2.jpg';
import img3 from './assets/images/totius-bild-3.jpg';
import img4 from './assets/images/totius-bild-4.jpg';
import img5 from './assets/images/totius-bild-5.jpg';
import img6 from './assets/images/totius-bild-6.jpg';
import img7 from './assets/images/totius-bild-7.jpg';
import img8 from './assets/images/totius-bild-8.jpg';
import img9 from './assets/images/totius-bild-9.jpg';
import img10 from './assets/images/totius-bild-10.jpg';
import img11 from './assets/images/totius-bild-11.jpg';
import img12 from './assets/images/totius-bild-12.jpg';
import img13 from './assets/images/totius-bild-13.jpg';
import img14 from './assets/images/totius-bild-14.jpg';
import form from './assets/images/form.svg';
import gold from './assets/images/gold.svg';
import callUs from './assets/images/call-us.svg';

let assets = {
    prefetch(assetName) {
        let url = assets.getUrl(assetName);
        if (url) {
            let img = new Image();
            img.src = url;
        }
    },

    getUrl(assetName) {
        switch (assetName) {
            case 'img1':
                return img1;
            case 'img2':
                return img2;
            case 'img3':
                return img3;
            case 'img4':
                return img4;
            case 'img5':
                return img5;
            case 'img6':
                return img6;
            case 'img7':
                return img7;
            case 'img8':
                return img8;
            case 'img9':
                return img9;
            case 'img10':
                return img10;
            case 'img11':
                return img11;
            case 'img12':
                return img12;
            case 'img13':
                return img13;
            case 'img14':
                return img14;
            case 'form':
                return form;
            case 'gold':
                return gold;
            case 'callUs':
                return callUs;

            default: {
                console.error("Could not find asset '" + assetName + "'");
                return undefined;
            }
        }
    },
};

export default assets;
