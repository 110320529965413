import getLifeInsuranceAdvice from './lifeInsurance';
import getHomeInsuranceAdvice from './homeinsurance';
import getVehicleInsuranceAdvice from './vehicle';
import getExtraVehicleInsuranceAdvice from './vehicle2';
import getExtraInsuranceAdvice from './extraInsurance';
import getKidsInsuranceAdvice from './kids';
import getBoatInsuranceAdvice from './boat';
import getTravelInsuranceAdvice from './travel';
import getHealthInsuranceAdvice from './health';
import getPetsInsuranceAdvice from './pets';
import getExtraAdvice from './extraAdvice';
import getCompanyInsuranceAdvice from './company';
import getOffice from './getOffice';

// This aggregates all individual insurance advices to an array
export default function getInsuranceAdvise(userModel, level) {
    let advice = [];

    let office = getOffice();
    const officeQs = office.querystring || "goteborg-och-bohuslan";

    console.log('Office:', office);

    advice = advice.concat(getLifeInsuranceAdvice(userModel, level, officeQs));
    advice = advice.concat(getHomeInsuranceAdvice(userModel, level, officeQs));
    advice = advice.concat(getKidsInsuranceAdvice(userModel, level, officeQs));
    advice = advice.concat(getHealthInsuranceAdvice(userModel, level, officeQs));
    advice = advice.concat(getVehicleInsuranceAdvice(userModel, level, officeQs));
    advice = advice.concat(getBoatInsuranceAdvice(userModel, level, officeQs));

    advice = advice.concat(getExtraVehicleInsuranceAdvice(userModel, level, officeQs));

    advice = advice.concat(getTravelInsuranceAdvice(userModel, level, officeQs));

    advice = advice.concat(getPetsInsuranceAdvice(userModel, level, officeQs));
    advice = advice.concat(getCompanyInsuranceAdvice(userModel, level, officeQs));

    advice = advice.concat(getExtraInsuranceAdvice(userModel, level, officeQs));
    advice = advice.concat(getExtraAdvice(userModel, level, officeQs));

    return advice;
}
