import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {withRouter} from 'react-router-dom';
import AnswerItem from './answeritems/AnswerItem';
import './interviewQuestion.scss';

class InterviewQuestion extends Component {
    static propTypes = {
        activeQuestion: PropTypes.number.isRequired,
        question: PropTypes.shape({
            text: PropTypes.string.isRequired,
        }),
        form: PropTypes.object,
        userModel: PropTypes.object,
        onSubmitAnswer: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {hidden: false, selectedItems: 0};
        this.answerClicked = this.answerClicked.bind(this);

        this.pendingAnswers = []; // For multiselect questions. This is
        // filled with the answers the user enables when toggling items, but
        // since they aren't committed to the header until the user clicks
        // the submit button ('Klar') we keep track of them in this array
        // and submit them all when the user clicks that button.
    }

    // This is called whenever the user clicks a button with an answer (AnswerItem).
    // The same handler is used for both multi select cards and cards where the answer is
    // submitted immediately upon click.
    // The handler is called with an object on the following format:
    //
    //  {
    //     question: { ... }  // See questiondata.js for structure
    //                        // of items in the root array. This
    //                        // is one of those questions.
    //
    //     answer: { ... }    // The answers in the answerOptions
    //                        // array in the above question object.
    //                        // that is associated with the answer item
    //  }
    //
    answerClicked(questionAnswerCombo) {
        let {question, answer} = questionAnswerCombo;

        if (question.multiSelect && answer.submitMultiSelect !== true) {
            // This is a multi select quesiton AND the user has
            // clicked one of the answers to toggle it.
            // The user has NOT tapped the button that submits the
            // answers since submitMultiSelect isn't true.

            let add = true;
            // Check if this item already has been added to the
            // pending answers. If so, remove that as the user has
            // toggled it off again.
            for (let i = 0; i < this.pendingAnswers.length; i++) {
                if (this.pendingAnswers[i] === answer) {
                    // User has toggled back the item - remove it from the array of pending answers
                    // and prevent it from being added again by setting add to false
                    this.pendingAnswers.splice(i, 1);
                    add = false;
                    break;
                }
            }
            if (add) {
                // The answer was toggled on, so let's add it to the queue of answers to save
                // when the user moves to the next card.
                // this.pendingAnswers.push(questionAnswerCombo);
                this.pendingAnswers.push(answer);
            }
            this.setState({selectedItems: this.pendingAnswers.length});
            return; // Button has been toggeld - nothing to save to the model yet.
        }

        if (!this.props.onSubmitAnswer) {
            console.error('Unexpected answer received', questionAnswerCombo);
            return;
        }

        // Extract the search term (query paramter) from the current location
        // and preserve that in the new url. Typicaly, this is the office
        // used for links. Example: ?kontor=alvsborg
        let search = this.props.history.location.search;

        this.props.history.push({
            search: search,
            state: {answerIndex: this.props.activeQuestion},
        });

        if (question.multiSelect === true) {
            // We should sumit all the answers that have been queued up
            this.props.onSubmitAnswer(question, this.pendingAnswers);
        } else {
            // We should submit only answer the user tapped on. Note that
            // the API expets an array of {question, answer} items, so the
            // single response is wrapped into an array of one item.
            // this.props.onSubmitAnswer(question, [{question, answer}]);
            this.props.onSubmitAnswer(question, [answer]);
        }
        this.pendingAnswers = [];
        this.setState({selectedItems: 0});
    }

    renderAnswerOptions(question) {
        // TODO: find better way to produce unique keys
        return question.answerOptions.map((answerOption, index) => {
            let key = index + answerOption.text;
            let text = answerOption.text;
            let submitStyle = undefined; // Not a submitbutton

            // The code below updates the label on the multiselect button
            // from "Nej" to "Klar" or similar when an item has been selected
            if (answerOption.textWhenSelected !== undefined && this.pendingAnswers.length > 0) {
                text = answerOption.textWhenSelected;
            }

            if (answerOption.submitMultiSelect || answerOption.submitStyle) {
                // submit: There's something to submit
                // submitNone: There's nothing to submit
                if (answerOption.submitStyle) {
                    submitStyle = answerOption.submitStyle; // Explicit style
                } else {
                    // Dynamic based on whether there has been selected options or not
                    submitStyle = this.pendingAnswers.length > 0 ? 'submit' : 'submitNone';
                }
            }

            return (
                <AnswerItem
                    key={key}
                    text={text}
                    canToggle={question.multiSelect === true && answerOption.submitMultiSelect !== true}
                    handleSelection={this.answerClicked}
                    submitStyle={submitStyle}
                    selectionData={{
                        question: question,
                        answer: answerOption,
                    }}
                />
            );
        });
    }

    render() {
        const {question, questionIndex, userModel} = this.props;
        let text = question.text;
        if (userModel.hasPartner && question.text2 !== undefined) {
            text = question.text2;
        }
        let textSpan = <span className="firstLineOfText">{text}</span>;

        let extraLine;
        if (question.secondLineOfText) {
            extraLine = (
                <span className="secondLineOfText">
                    <br />
                    {question.secondLineOfText}
                </span>
            );
        }

        let answerLayoutClass = 'answersContainer';
        if (question.answerOptions.length === 1) {
            answerLayoutClass = 'singleItemAnswersContainer';
        } else if (question.answerOptions.length > 8) {
            answerLayoutClass = 'manyItemAnswersContainer';
        }

        return (
            <TransitionGroup className="transition-question-group-root">
                <CSSTransition key={questionIndex} timeout={800} classNames="question-animation">
                    <div className="questionRoot">
                        <div className="titleContainer">
                            {textSpan}
                            {extraLine}
                        </div>
                        <div className={answerLayoutClass}>{this.renderAnswerOptions(question)}</div>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        );
    }
}

export default withRouter(InterviewQuestion);
