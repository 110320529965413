import utils from '../utils';

// This aggregates all individual insurance advices to an array
export default function getExtraInsuranceAdvice(userModel, level, kontor) {
    let advice = [];

    let salary = 25000; // Gissa
    if (userModel.salary.min !== undefined && userModel.salary.max !== undefined) {
        // We have the info - refine
        salary = (userModel.salary.min + userModel.salary.max) / 2;
    }

    // Require 50% confidence or above at 45k in salary
    let threshold50 = utils.blendAndClamp(99, 50, 30000, salary, 45000);
    // Require 75% confidence or above at 45k in salary
    let threshold75 = utils.blendAndClamp(99, 75, 35000, salary, 45000);

    // 300-500k income
    if (level >= threshold50) {
        advice.push({
            key: 'valueables',
            title: 'Allrisk för angivna värdeföremål',
            titleShort: 'Värdeföremål',
            info: 'Dina särskilt värdefulla saker kan skadas eller förloras genom andra händelser än de som täcks av hemförsäkringen. Det är för de tillfällena allriskförsäkringen finns.',
            link: 'https://www.lansforsakringar.se/globalassets/aa-global/dokument/information/05333-produktblad-allrisk-special.pdf',
        });
    }

    if (level >= threshold75) {
        advice.push({
            key: 'collectons-of-valueables',
            title: 'Samlingar, smycken och armbandsklockor',
            titleShort: 'Samlingar',
            info: 'I hemförsäkringen är högsta ersättningsbelopp för smycken och armbandsklockor 200 000 kronor. Har du sådan egendom för högre värden måste du komplettera din hemförsäkring med den här tilläggsförsäkringen. Den gäller vid skada på eller förlust av smycken och armbandsklockor samt mynt-, sedel- och frimärkssamlingar i bostaden.',
            link: 'https://www.lansforsakringar.se/globalassets/aa-global/dokument/information/05334-forkopsinfo-samlingar-smycken-klockor.pdf',
        });
    }

    console.log('Extra insurance:', advice);
    return advice;
}
