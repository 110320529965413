export const ADD_ANSWER = 'answers:addAnswer';

export function addAnswer(answer) {
    console.log('Action: addAnswer ->', answer);

    return {
        type: ADD_ANSWER,
        payload: answer,
    };
}
