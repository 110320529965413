export const SET_INSURANCE_LEVEL = 'insuranceLevel:set';

export function setInsuranceLevel(level) {
    console.log('Action: setInsurancelevel ->', level);

    return {
        type: SET_INSURANCE_LEVEL,
        payload: level,
    };
}
