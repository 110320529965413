import utils from '../utils';
import { offices } from '../offices';

// This aggregates all individual insurance advices to an array
export default function getOffice() {
    const qsOffice = utils.getQueryVariable('kontor');

    // Find the office using the query string
    // or fallback to the Göteborg och Bohuslän office if nothing was found
    const officeObj =
        offices.find(office => office.querystring === qsOffice)
        ||
        offices.find(office => office.querystring === 'goteborg-och-bohuslan');

    // Return the office
    return officeObj;
}
