export const questions = [
    {
        id: 'age',
        text: 'Hur gammal är du?',
        imageName: 'img2',

        answerOptions: [
            {
                text: '18+',
                userModel: {
                    age: { min: 18, max: 29 },
                },
            },
            {
                text: '30+',
                userModel: {
                    age: { min: 30, max: 39 },
                },
            },
            {
                text: '40+',
                userModel: {
                    age: { min: 40, max: 49 },
                },
            },
            {
                text: '50+',
                userModel: {
                    age: { min: 50, max: 59 },
                },
            },
            {
                text: '60+',
                userModel: {
                    age: { min: 60, max: 69 },
                },
            },
            {
                text: '70+',
                userModel: {
                    age: { min: 70, max: 79 },
                },
            },
        ],
    },
    {
        id: 'house',
        text: 'Hur bor du idag?',
        imageName: 'img3',
        answerOptions: [
            {
                text: 'Hyresrätt',
                userModel: {
                    residence: { rentalAppartment: true },
                },
            },
            {
                text: 'Bostadsrätt',
                userModel: {
                    residence: { ownAppartment: true },
                },
            },
            {
                text: 'Villa',
                userModel: {
                    residence: { ownHouse: true },
                },
            },
            {
                text: 'Radhus',
                userModel: {
                    residence: { ownHouse: true },
                },
            },
            {
                text: 'Parhus',
                userModel: {
                    residence: { ownHouse: true },
                },
            },
            {
                text: 'Övrigt',
            },
        ],
    },
    {
        id: 'extra-houses',
        text: 'Finns fler hus på tomten?',
        secondLineOfText: 'Du kan välja flera.',

        filter: (userModel) => {
            return userModel.residence.ownHouse === true;
        },
        multiSelect: true,
        imageName: 'img4',
        answerOptions: [
            {
                text: 'Växthus',
                userModel: { ownHouseExtrasGreenHouse: true },
            },
            {
                text: 'Friggebod',
                userModel: { ownHouseExtrasFriggebod: true },
            },
            {
                text: 'Garage',
                userModel: { ownHouseExtrasGarage: true },
            },
            {
                text: 'Gäststuga',
                userModel: { ownHouseExtrasGuestCottage: true },
            },
            {
                text: 'Övrigt',
                userModel: { ownHouseExtrasOther: true },
            },
            {
                text: 'Inga fler hus',
                textWhenSelected: 'Klar',
                submitMultiSelect: true,
            },
        ],
    },
    {
        id: 'partner',
        text: 'Delar du hushåll med en partner?',
        imageName: 'img5',
        answerOptions: [
            {
                text: 'Ja',
                answerText: 'Delat hushåll',
                userModel: {
                    hasPartner: true,
                },
            },
            {
                text: 'Nej',
                answerText: 'Eget hushåll',
                userModel: {
                    hasPartner: false,
                },
            },
        ],
    },
    {
        id: 'kids',
        text: 'Har du barn under 18 år?',
        text2: 'Har ni barn under 18 år?',
        imageName: 'img6',
        answerOptions: [
            {
                text: 'Ja',
                answerText: 'Barn',
                userModel: {
                    hasKids: true,
                },
            },
            {
                text: 'Nej',
                answerText: 'Inga barn',
                userModel: {
                    hasKids: 0,
                },
            },
        ],
    },
    {
        id: 'country-houses',
        text: 'Har du ett fritidshus?',
        text2: 'Har ni ett fritidshus?',

        imageName: 'img7',
        answerOptions: [
            {
                text: 'Ja',
                answerText: 'Fritidshus',
                userModel: { countryHouse: true },
            },
            {
                text: 'Nej',
                answerText: 'Inget fritidshus',
                userModel: { countryHouse: false },
            },
        ],
    },
    {
        id: 'salary',
        text: 'Vad är din månadslön?',
        imageName: 'img8',
        answerOptions: [
            {
                text: '0 - 10 000',
                userModel: { salary: { min: 0, max: 10000 } },
            },
            {
                text: '10 - 15 000',
                userModel: { salary: { min: 10000, max: 15000 } },
            },
            {
                text: '15 - 20 000',
                userModel: { salary: { min: 15000, max: 20000 } },
            },
            {
                text: '20 - 25 000',
                userModel: { salary: { min: 20000, max: 25000 } },
            },
            {
                text: '25 - 30 000',
                userModel: { salary: { min: 25000, max: 30000 } },
            },
            {
                text: '30 - 35 000',
                userModel: { salary: { min: 30000, max: 35000 } },
            },
            {
                text: '35 - 40 000',
                userModel: { salary: { min: 35000, max: 40000 } },
            },
            {
                text: '40 000 +',
                userModel: { salary: { min: 40000, max: 50000 } },
            },
        ],
    },
    {
        id: 'debt',

        text: 'Hur mycket lån har du sammanlagt?',
        text2: 'Hur mycket lån har ni sammanlagt i hushållet?',

        imageName: 'img9',
        answerOptions: [
            {
                text: 'Inget',
                answerText: 'Inget lån',
                userModel: { debt: { min: 0, max: 0 } },
            },
            {
                text: '0 - 1Mkr',
                answerText: '0 - 1 Mkr',
                userModel: { debt: { min: 0, max: 1000000 } },
            },
            {
                text: '1 - 2Mkr',
                answerText: '1 - 2Mkr',
                userModel: { debt: { min: 1000000, max: 2000000 } },
            },
            {
                text: '2 - 3Mkr',
                answerText: '2 - 3Mkr',
                userModel: { debt: { min: 2000000, max: 3000000 } },
            },
            {
                text: '3 - 4Mkr',
                answerText: '3 - 4Mkr',
                userModel: { debt: { min: 3000000, max: 4000000 } },
            },
            {
                text: 'Över 4Mkr',
                answerText: '4Mkr +',
                userModel: { debt: { min: 4000000, max: 5000000 } },
            },
        ],
    },
    {
        id: 'car',

        text: 'Har du bil?',
        text2: 'Har ni bil?',

        imageName: 'img10',
        answerOptions: [
            {
                text: 'Ja',
                answerText: 'Bil',
                userModel: { vehicle_carCount: 1 },
            },
            {
                text: 'Nej',
                answerText: 'Ingen bil',
                userModel: { vehicle_carCount: 0 },
            },
        ],
    },
    {
        id: 'boat',
        text: 'Har du båt?',
        text2: 'Har ni båt?',

        imageName: 'img11',
        answerOptions: [
            {
                text: 'Ja',
                answerText: 'Båt',
                userModel: { vehicle_boat: true },
            },
            {
                text: 'Nej',
                answerText: 'Ingen båt',
                userModel: { vehicle_boat: false },
            },
        ],
    },
    {
        id: 'other-vehicles',
        text: 'Har du andra fordon?',
        text2: 'Har ni andra fordon?',
        secondLineOfText: 'Du kan välja flera.',

        imageName: 'img12',
        multiSelect: true,
        answerOptions: [
            {
                text: 'MC',
                userModel: { vehicle_mc: true },
            },
            {
                text: 'Moped',
                userModel: { vehicle_moped: true },
            },
            {
                text: 'Entusiastbil',
                userModel: { vehicle_entusiast: true },
            },
            {
                text: 'Husbil',
                userModel: { vehicle_husbil: true },
            },
            {
                text: 'Husvagn',
                userModel: { vehicle_husvagn: true },
            },
            {
                text: 'Lätt lastbil',
                userModel: { vehicle_lightTruck: true },
            },
            {
                text: 'Släpvagn',
                userModel: { vehicle_slapvagn: true },
            },
            {
                text: 'Terränghjuling',
                userModel: { vehicle_terrain: true },
            },
            {
                text: 'Snöskoter',
                userModel: { vehicle_snoskoter: true },
            },
            {
                text: 'Inget fordon',
                textWhenSelected: 'Klar',
                submitMultiSelect: true,
            },
        ],
    },
    // {
    //     id: 'pets',
    //     text: 'Har du några djur?',
    //     text2: 'Har ni några djur?',
    //     secondLineOfText: 'Du kan välja flera.',

    //     multiSelect: true,

    //     imageName: 'img13',
    //     answerOptions: [
    //         {
    //             text: 'Hund',
    //             userModel: { pets_dog: true },
    //         },
    //         {
    //             text: 'Katt',
    //             userModel: { pets_cat: true },
    //         },
    //         {
    //             text: 'Häst',
    //             userModel: { pets_horse: true },
    //         },
    //         {
    //             text: 'Smådjur',
    //             userModel: { pets_small: true },
    //         },
    //         {
    //             text: 'Övrigt',
    //             userModel: { pets_other: true },
    //         },
    //         {
    //             text: 'Inga djur',
    //             textWhenSelected: 'Klar',
    //             submitMultiSelect: true,
    //         },
    //     ],
    // },
    {
        id: 'company',
        text: 'Har du företag?',

        imageName: 'img8',
        answerOptions: [
            {
                text: 'Ja',
                answerText: 'Företag',
                userModel: { hasCompany: true },
            },
            {
                text: 'Nej',
                answerText: 'Inget företag',
                userModel: { hasCompany: false },
            },
        ],
    },
];
