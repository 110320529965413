// This aggregates all individual insurance advices to an array
export default function getVehicleInsuranceAdvice(userModel, level, kontor) {
    let advice = [];

    if (userModel.vehicle_carCount !== undefined && userModel.vehicle_carCount > 0) {
        // har man barn så rekommendera tilläggsförsäkringen starkare än de utan barn
        let separateAdviceAboutMer = (userModel.hasKids && level > 40) || level > 80;

        let extraText = '';
        if (!separateAdviceAboutMer) {
            // Vi kommer inte rekommendera separat om MER. Då kilar vi in lite extra
            // text i grundrekommendationen istället
            extraText =
                ' Om det är viktigt att ha tillgång till en bil i alla lägen kan du även teckna tilläggsförsäkring MER.';
        }

        if (level < 30) {
            advice.push({
                key: 'car-insurance',
                title: 'Bil: Trafikförsäkring',
                titleShort: 'Bil',
                info: 'Alla bilar ska enligt svensk lag ha en trafikförsäkring. En trafikförsäkring är den billigaste formen av bilförsäkring och ersätter personskador samt skador på andras egendom.',
                link: 'https://www.lansforsakringar.se/' + kontor + '/privat/forsakring/bilforsakring/om-trafikforsakring/',
            });
        } else if (level < 80) {
            advice.push({
                key: 'car-insurance',
                title: 'Bil: Halvförsäkring',
                titleShort: 'Bil',
                info:
                    'Täcker personskador och skador på annans egendom. Täcker de flesta olyckor andra orsakar dig. Överväg helförsäkring om du även vill täcka skador på ditt fordon.' +
                    extraText,
                link: 'https://www.lansforsakringar.se/' + kontor + '/privat/forsakring/bilforsakring/',
                linkText: 'Räkna på pris här',
            });
        } else {
            advice.push({
                key: 'car-insurance',
                title: 'Bil: Helförsäkring',
                titleShort: 'Bil',
                info:
                    'Täcker personskador och skador på annans egendom. Täcker de flesta olyckor andra orsakar dig. Täcker skador på ditt fordon vid trafikolycka eller skadegörelse.' +
                    extraText,
                link: 'https://www.lansforsakringar.se/' + kontor + '/privat/forsakring/bilforsakring/',
                linkText: 'Räkna på pris här',
            });
        }

        // har man barn så rekommendera tilläggsförsäkringen starkare än de utan barn
        if (separateAdviceAboutMer) {
            advice.push({
                key: 'car-insurance-mer',
                title: 'Bil: Tilläggsförsäkring MER',
                titleShort: 'Bil MER',
                info: 'Välj tilläggsförsäkringen MER om du vill ha ett extra skydd i din bilförsäkring och om det är viktigt att ha tillgång till en bil i alla lägen.',
                link: 'https://www.lansforsakringar.se/globalassets/aa-global/dokument/information/01524-information-mer-forsakring.pdf',
            });
        }
    }

    console.log('Vehicle insurance:', advice);
    return advice;
}
