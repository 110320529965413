import {connect} from 'react-redux';
import InterviewHeader from '../components/common/interviewheader/InterviewHeader';

const mapStateToProps = (state) => {
    return {
        appState: state.appState,
        userModel: state.userModel,
        answers: state.answers,
    };
};

export default connect(mapStateToProps)(InterviewHeader);
