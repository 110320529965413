// This aggregates all individual insurance advices to an array
export default function getExtraAdvice(userModel, level, kontor) {
    let advice = [];

    if (userModel.residence.ownHouse === true && level > 75) {
        advice.push({
            key: 'extra-advice-larm',
            title: 'Larm',
            titleShort: 'Larma',
            info: 'Ett larm är en bra förebyggande åtgärd som kan förhindra eller skrämma bort någon som försöker bryta sig in. Här kan du läsa mer om olika inbrottslarm och andra metoder för att motverka inbrott.',
            link: 'https://www.lansforsakringar.se/' + kontor + '/privat/forsakring/forebygga-skador/inbrott-och-stold/larm/',
        });
    }

    if (level > 85) {
        advice.push({
            key: 'extra-advice-prevent',
            title: 'Förebygga skador',
            titleShort: 'Förebygg',
            info: 'Varje år inträffar tusentals skador i svenska hem. Med enkla små medel så kan vi stoppa skadan innan något gått snett.',
            link: 'https://www.lansforsakringar.se/' + kontor + '/privat/forsakring/forebygga-skador/',
        });
    }

    console.log('Extra advice:', advice);
    return advice;
}
