// This aggregates all individual insurance advices to an array
export default function getHomeInsuranceAdvise(userModel, level, kontor) {
    let advice = [];
    if (userModel.residence.ownHouse) {
        advice.push({
            key: 'home',
            title: 'Villahemförsäkring',
            titleShort: 'Villahem',
            info: 'Med Villahemförsäkringen kombinerar du villaförsäkring och hemförsäkring. Den fungerar som en vanlig hemförsäkring, men gäller också för skador på själva huset och tomten det står på.',
            link: 'https://www.lansforsakringar.se/' + kontor + '/privat/forsakring/hemforsakring/villa/',
            linkText: 'Räkna på pris här',
        });

        // Tillägg
        if (level >= 60) {
            advice.push({
                key: 'home-all-risk',
                title: 'Allrisk byggnad',
                info: 'Skyddar försäkrade byggnader och tomtmarken vid skada genom plötsliga och oförutsedda händelser som inte omfattas av grundförsäkringen.',
                link: 'https://www.lansforsakringar.se/' + kontor + '/privat/forsakring/hemforsakring/villa/',
                linkText: 'Räkna på pris här',
            });
        }
    } else if (userModel.residence.ownAppartment) {
        advice.push({
            key: 'home',
            title: 'Bostadsrättsförsäkring',
            titleShort: 'Bostadsrätt',
            info: 'Hemförsäkring för dig som äger en bostadsrätt. Vår försäkring ger dig ett bra ekonomiskt skyddsnät. Bostadsrättsförsäkringen ger ett skydd för fast inredning, balkong med mera som du ansvarar för.',
            link: 'https://www.lansforsakringar.se/' + kontor + '/privat/forsakring/hemforsakring/bostadsratt/',
            linkText: 'Räkna på pris här',
        });
    } else if (userModel.residence.rentalAppartment) {
        advice.push({
            key: 'home',
            title: 'Hyresrättsförsäkring',
            titleShort: 'Hyresrätt',
            info: 'Hemförsäkring för dig som hyr din bostad. Vår försäkring ger dig ett bra ekonomiskt skyddsnät och reseskydd i hela världen i upp till 45 dagar.',
            link: 'https://www.lansforsakringar.se/' + kontor + '/privat/forsakring/hemforsakring/hyresratt/',
            linkText: 'Räkna på pris här',
        });
    }

    if (
        userModel.ownHouseExtrasGreenHouse ||
        userModel.ownHouseExtrasFriggebod ||
        userModel.ownHouseExtrasGarage ||
        userModel.ownHouseExtrasGuestCottage ||
        userModel.ownHouseExtrasOther
    ) {
        advice.push({
            key: 'extra-house',
            title: 'Tips: Ange extra hus på tomten',
            titleShort: 'Extra hus',
            info: 'Du behöver ange alla byggnader du har på tomten i ditt försäkringsbrev för att de ska inkluderas i din försäkring.',
        });
    }

    if (userModel.countryHouse) {
        advice.push({
            key: 'secondhome',
            title: 'Fritidshus',
            info: 'Fritidshusförsäkringen ger din sommarstuga eller lantställe ett brett skydd för skador, olyckor och stölder på ditt hus/fastighet, året om.',
            link: 'https://www.lansforsakringar.se/' + kontor + '/privat/forsakring/hemforsakring/fritidshus/',
            linkText: 'Räkna på pris här',
        });
    }
    console.log('Home insurance:', advice);
    return advice;
}
