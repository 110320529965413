export const NEXT_QUESTION = 'appState:nextQuestion';
export const GO_TO_RESULT = 'appState:goToResult';

export function incrementActiveQuestionNumber(userModel) {
    return {
        type: NEXT_QUESTION,
        payload: {userModel: userModel},
    };
}

export function goToResultPage(userModel) {
    return {type: GO_TO_RESULT, payload: {userModel: userModel}};
}
