export const sendEmail = async (props) => {
    const emailBody = {
        subject: props?.subject || 'Azure Communication Services Email',
        plainText: props?.plainText || 'This is an test message from ' + document.location.href,
        recipientEmail: props?.recipientEmail || 'anton@lfant.se',
        recipientName: props?.recipientName || 'Totius',
    };

    try {
        const response = await fetch('/api/SendEmail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(emailBody),
        });

        if (response.ok) {
            // If the response status is 200-299
            const data = await response.json();
            console.log('Email sent successfully!', data);
            return true; // Successfully sent the email
        } else {
            const errorData = await response.body();
            console.error('Failed to send email. Server responded with:', errorData);
            return false; // Email sending failed
        }
    } catch (error) {
        console.error('Failed to send email.', error);
        return false; // Failed to send the email
    }
};

export const sendTest = () => {
    fetch('/api/Test', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: 'my test',
    })
        .then((response) => response.json())
        .then((data) => {
            console.log('Test was successful!', data);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
};
