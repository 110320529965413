import React, {Component} from 'react';
import PropTypes from 'prop-types';
import sizeMe from 'react-sizeme';
import './answerItem.scss';

class AnswerItem extends Component {
    static propTypes = {
        text: PropTypes.string.isRequired,
        selectionData: PropTypes.object,
        handleSelection: PropTypes.func,
        isNarrow: PropTypes.bool,
        isTick: PropTypes.bool,
        isMultiSelected: PropTypes.bool,
        canToggle: PropTypes.bool,
        submitStyle: PropTypes.string,
        resultItem: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            toggleStatePressed: false,
            fadeInOpacity: props.isNarrow || props.isTick ? 0 : 1,
        };
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        this.setState({fadeInOpacity: 1});
    }

    handleClick() {
        if (this.props.canToggle === true) {
            this.setState({toggleStatePressed: !this.state.toggleStatePressed});
        }

        if (this.props.handleSelection) {
            this.props.handleSelection(this.props.selectionData);
        }
    }

    render() {
        let answerContainerClassname = this.props.isNarrow ? 'answerContainerNarrow' : 'answerContainer';
        if (this.props.isTick) {
            answerContainerClassname = 'answerContainerTicks';
        }

        if (this.props.canToggle && this.state.toggleStatePressed) {
            answerContainerClassname = 'answerContainerMultiSelected';
        }
        if (this.props.submitStyle === 'submit') {
            answerContainerClassname = 'answerContainerSubmit';
        } else if (this.props.submitStyle === 'submitNone') {
            answerContainerClassname = 'answerContainerSubmitNone';
        }

        let style = {opacity: this.state.fadeInOpacity};

        return (
            <div className="answerItemRoot">
                <div className={answerContainerClassname} onClick={this.handleClick} style={style}>
                    <div className="answerText">{this.props.text}</div>
                </div>
            </div>
        );
    }
}

export default sizeMe()(AnswerItem);
