// This aggregates all individual insurance advices to an array
export default function getPetsInsuranceAdvice(userModel, level, kontor) {
    let advice = [];

    if (userModel.pets_dog === true) {
        if (level >= 80) {
            advice.push({
                key: 'pets-dog',
                title: 'Agria Hundrasförsäkring',
                titleShort: 'Hund',
                info: 'Omfattande, specialanpassad och populär hundförsäkring för dig som vill ha ett brett och tryggt skydd för din hund.',
                link: 'https://www.agria.se/hund/hundforsakring/',
            });
        } else if (level >= 20) {
            advice.push({
                key: 'pets-dog',
                title: 'Agria Mini för din hund',
                titleShort: 'Hund',
                info: 'Billig och enkel hundförsäkring för dig som vill hålla nere kostnaderna med ett begränsat skydd.',
                link: 'https://www.agria.se/hund/hundforsakring/',
            });
        }

        if (userModel.hasPartner === false && level >= 50) {
            advice.push({
                key: 'pets-dog-extra',
                title: 'Agria Trygghet - tilläggsförsäkring för din hund',
                titleShort: 'Trygghet Hund',
                info: 'Med Agria Trygghet kan du känna dig trygg om du blir sjuk och oförmögen att ta hand om din hund eller om något skulle hända din hund i samband med att du är ute och reser.',
                link: 'https://www.agria.se/hund/hundforsakring/agria-trygghet-hund/',
            });
        }
    }

    if (userModel.pets_cat === true) {
        if (level >= 50) {
            advice.push({
                key: 'pets-cat',
                title: 'Agria Kattförsäkring',
                titleShort: 'Katt',
                info: 'Omfattande, specialanpassad och populär kattförsäkring som ger dig ett brett skydd för din katt',
                link: 'https://www.agria.se/katt/kattforsakring/',
            });
        }
        if (userModel.hasPartner === false && level >= 75) {
            advice.push({
                key: 'pets-cat-extra',
                title: 'Agria Trygghet - tilläggsförsäkring för din katt',
                titleShort: 'Trygghet Katt',
                info: 'Med Agria Trygghet kan du känna dig trygg om du blir sjuk och oförmögen att ta hand om din katt eller om något skulle hända din katt i samband med att du är ute och reser.',
                link: 'https://www.agria.se/katt/kattforsakring/agria-trygghet/',
            });
        }
    }

    if (userModel.pets_horse === true) {
        if (level >= 50) {
            advice.push({
                key: 'pets-horse',
                title: 'Agria A1 Safe för din häst',
                titleShort: 'Häst',
                info: 'Vårt mest heltäckande alternativ. Få ersättning för undersökning, behandling och vård om din häst blir sjuk eller skadad.',
                link: 'https://www.agria.se/hast/hastforsakring/',
            });

            if (level >= 90) {
                advice.push({
                    key: 'pets-horse-extra',
                    title: 'Agria A1 Guld - tilläggsförsäkring för din häst',
                    titleShort: 'Häst',
                    info: 'Med Agrias tilläggsförsäkringar får du ett ännu bättre skydd för din häst.                    ',
                    link: 'https://www.agria.se/hast/hastforsakring/#/tillagg',
                });
            }
        } else {
            advice.push({
                key: 'pets-horse',
                title: 'Agria A2 Limited för din häst',
                titleShort: 'Häst',
                info: 'Vår minst omfattande och billigaste veterinärvårdsförsäkring.',
                link: 'https://www.agria.se/hast/hastforsakring/',
            });
        }
    }

    if (userModel.pets_small === true && level >= 70) {
        advice.push({
            key: 'pets-small',
            title: 'Agria Smådjursförsäkring',
            titleShort: 'Smådjur',
            info: 'Vi har försäkringar för de flesta smådjuren och fåglarna. När du köper försäkring hos oss får du en försäkring anpassad efter det djurslag du väljer att försäkra.',
            link: 'https://www.agria.se/smadjur/smadjursforsakring/',
        });
    }

    if (userModel.pets_other === true && level >= 50) {
        advice.push({
            key: 'pets-other',
            title: 'Agria Djurförsäkring',
            titleShort: 'Djur',
            info: 'Agria Djurförsäkring förser Sveriges djur, deras ägare och Sveriges växtodlare med flexibla försäkringslösningar.',
            link: 'https://www.agria.se/',
        });
    }

    console.log('Pet insurance:', advice);
    return advice;
}
