import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './recommendationitem.scss';
import assets from '../../../assets';
import {sendEmail} from '../../../apiCalls';

class RecommendationItem extends Component {
    static propTypes = {
        linkText: PropTypes.string,
        text: PropTypes.string,
        info: PropTypes.string,
        icon: PropTypes.string,
        hasButton: PropTypes.bool,
        buttonText: PropTypes.string,
        officeObj: PropTypes.object,
        shareLink: PropTypes.string,
        userModel: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            submit: null, // null, "sending", "sent", "error",
            accept_pul: false,
            expand_pul_wrapper: false,
        };
    }

    submitForm = async (e, recipientEmail) => {
        e.preventDefault();
        this.setState({submit: 'sending'});

        const name = e.target.name.value;
        const email = e.target.email.value;
        const tel = e.target.tel.value;

        let message = `Från: \n${name} \n\n`;
        message = `${message}E-post:  \n${email} \n\n`;
        message = `${message}Telefonnummer:  \n${tel} \n\n`;
        message = `${message}Länken till försäkringsgenomgången: \n${this.props.shareLink} \n\n`;
        message = `${message}Rådata: \n${JSON.stringify(this.props.userModel, null, ' ')} \n\n`;

        // console.log('message', message);
        const reply = await sendEmail({plainText: message, recipientEmail: recipientEmail});

        // wait with re-setting state to show the "sending" state for a while.
        setTimeout(() => {
            if (reply) this.setState({submit: 'sent'});
            else this.setState({submit: 'error'});
        }, 1000);
    };
    /*

    +-----recommendationItemRoot-------------+
    | +--icon---+-recommendationContainer--+ |
    | |         |                          | |
    | |         |                          | |
    | |  icon   |        content           | |
    | |         |                          | |
    | |         |                          | |
    | |         |                          | |
    | +---------+--------------------------+ |
    +----------------------------------------+

    */

    getOfficeVisitingHoursLayout(officeObject) {
        return (
            <table>
                <tbody>
                    {officeObject.openingHours.map((time, index) => {
                        return (
                            <tr key={index}>
                                <td className="firstColumn">{time.day}</td>
                                <td className="secondColumn">{time.hours}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }

    getOfficeNameAndTelephoneLayout(officeObject) {
        return (
            <div className="officeAndPhoneNumberWrapper">
                <div>{officeObject.general.name}</div>
                <div>{officeObject.general.phone}</div>
            </div>
        );
    }

    render() {
        let link;
        let content;
        let officeQs = this.props.officeObj?.querystring || 'goteborg-och-bohuslan';

        let image;
        if (this.props.icon) {
            let url = assets.getUrl(this.props.icon);
            image = <img className="recommendationIcon" src={url} width="45px" height="45px" alt="" />;
        }

        const emailString = this.props.officeObj?.general.email || '';

        if (this.props.hasButton === true) {
            const pulUrl =
                'https://www.lansforsakringar.se/' + officeQs + '/privat/om-oss/kontakta-oss/sidfot/personuppgifter/';
            const pulLink =
                'När du kontaktar oss godkänner du att vi behandlar de personuppgifter du lämnar. Här kan du läsa mer om hur vi behandlar personuppgifter: ' +
                pulUrl;
            const mailSubject = 'Kontakta mig';
            const mailLink = this.props.shareLink ? '\n\n\nLänken från försäkringsgenomgången:\n' + this.props.shareLink : '';
            const mailFormattedBody = encodeURIComponent(
                'Hej, \n\nJag vill gärna bli kontaktad för att få mer information angående mitt försäkringsinnehav. \n\nNamn: \nTelefonnummer: ' +
                    mailLink +
                    '\n\n' +
                    pulLink,
            );
            const mailToLink = 'mailto:' + emailString + '?subject=' + mailSubject + '&body=' + mailFormattedBody;
            content = (
                <div className="recommendationWithButtonContainer">
                    <div className="recommendationTextAndImageContainer">
                        {image}
                        <div className="recommendationTextWrapper">
                            <div className="recommendationText">{this.props.text}</div>
                            {this.getOfficeNameAndTelephoneLayout(this.props.officeObj)}

                            <div className="linkWrapper">
                                <a target="_blank" rel="noreferrer" href={mailToLink}>
                                    {emailString}
                                </a>
                            </div>
                            <div class="recommendationSubHeader">Fyll i epost och telefonnummer så hör vi av oss</div>

                            <div className="submit_overlay">
                                {this.state.submit === 'sending' && <div className="loader" />}
                                {this.state.submit === 'sent' && (
                                    <div className="done">
                                        <div className="checkmark">
                                            <svg
                                                width="35"
                                                height="27"
                                                viewBox="0 0 35 27"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M31.5 0.875C32.2997 0.875 33.1081 1.17125 33.7188 1.78125C34.9388 3.0025 34.9388 4.9975 33.7188 6.21875L14.3438 25.5938C13.7338 26.2038 12.925 26.5 12.125 26.5C11.325 26.5 10.5163 26.2038 9.90627 25.5938L1.78127 17.4687C0.561272 16.2475 0.561272 14.2525 1.78127 13.0313C3.00127 11.8113 4.99877 11.8113 6.21877 13.0313L12.125 18.9688L29.2813 1.78125C29.8919 1.17125 30.7003 0.875 31.5 0.875Z"
                                                    fill="#1b961b"
                                                />
                                            </svg>
                                        </div>
                                        <p>
                                            <b>Formuläret skickat</b> <br />
                                            Vi hör av oss inom kort.
                                        </p>
                                    </div>
                                )}
                            </div>
                            <form
                                onSubmit={(e) => this.submitForm(e, emailString)}
                                className={`submit_email ${
                                    (this.state.submit === 'sending' || this.state.submit === 'sent') && 'disabled'
                                }`}>
                                <input
                                    name="name"
                                    id="name"
                                    type="text"
                                    placeholder="Ditt namn"
                                    disabled={this.state.submit === 'sending' || this.state.submit === 'sent'}
                                    required
                                />
                                <input
                                    name="email"
                                    id="email"
                                    type="email"
                                    placeholder="E-post"
                                    disabled={this.state.submit === 'sending' || this.state.submit === 'sent'}
                                    required
                                />
                                <input
                                    name="tel"
                                    id="tel"
                                    type="tel"
                                    placeholder="Telefonnummer"
                                    disabled={this.state.submit === 'sending' || this.state.submit === 'sent'}
                                    required
                                />

                                <label className="acceptLabel">
                                    <input
                                        type="checkbox"
                                        name="accept_pul"
                                        onChange={(e) => this.setState({accept_pul: e.target.checked})}
                                        style={{marginRight: '8px'}}
                                    />
                                    Jag godkänner villkoren nedan
                                </label>
                                <button
                                    className="submitButton"
                                    type="submit"
                                    disabled={
                                        !this.state.accept_pul ||
                                        this.state.submit === 'sending' ||
                                        this.state.submit === 'sent'
                                    }>
                                    {this.state.submit === 'sending'
                                        ? 'Skickar...'
                                        : this.state.submit === 'sent'
                                        ? 'Skickat!'
                                        : 'Skicka'}
                                </button>
                                {this.state.submit === 'error' && (
                                    <div className="errorMessage">
                                        Kunde inte skicka formuläret, försök igen eller{' '}
                                        <a target="_blank" rel="noreferrer" href={mailToLink}>
                                            kontakta oss via e-post
                                        </a>{' '}
                                        istället.
                                    </div>
                                )}
                            </form>

                            <div className="pulWrapper">
                                {!this.state.expand_pul_wrapper ? (
                                    <>
                                        Genom att anmäla ditt intresse för att bli kontaktad, ger du ditt samtycke till att vi
                                        på Länsförsäkringar...
                                    </>
                                ) : (
                                    <>
                                        <p>
                                            Genom att anmäla ditt intresse för att bli kontaktad, ger du ditt samtycke till att
                                            vi på Länsförsäkringar registrerar och lagrar de personuppgifter du lämnar i
                                            formuläret. I vissa fall kan dessa uppgifter delas med en tredje part.
                                        </p>
                                        <p>
                                            De personuppgifter som vi behandlar i kontaktformuläret kan delas med tredje part
                                            Lfant är namn, telefonnummer och e-postadress. Vi behöver behandla dessa
                                            personuppgifter för att kunna kontakta dig.
                                        </p>
                                        <p>
                                            Dina uppgifter som du delar med oss och Lfant sparas under högst tre månader. Du kan
                                            när som helst dra tillbaka din intresseanmälan och begära att få dina uppgifter
                                            raderade.
                                        </p>
                                        <p>
                                            Dessa villkor är endast till för denna intresseanmälan kring att bli kontaktad för
                                            fler försäkringar baserat på formuläret du fyllt i, separata särskilda villkor
                                            gäller vid tecknande av försäkring och kan läsas i samband med tecknande av dessa.
                                        </p>
                                        <p>
                                            Här kan du läsa mer om hur vi{' '}
                                            <a target="_blank" rel="noreferrer" href={pulUrl}>
                                                behandlar personuppgifter
                                            </a>
                                        </p>
                                    </>
                                )}
                                <span
                                    className="pulWrapperButton"
                                    onClick={() => this.setState({expand_pul_wrapper: !this.state.expand_pul_wrapper})}>
                                    {!this.state.expand_pul_wrapper ? ' Läs mer' : ' Läs mindre'}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            );

            return <div className="recommendationItemRoot">{content}</div>;
        } else {
            if (this.props.link) {
                let linkText = this.props.linkText;

                if (linkText !== undefined && linkText !== '') {
                    linkText = <b>{linkText}</b>;
                } else {
                    linkText = <>Läs&nbsp;mer</>;
                }
                link = (
                    <a href={this.props.link} target="_blank" rel="noopener noreferrer">
                        {linkText}
                    </a>
                );
            }

            content = (
                <div className="recommendationContainer">
                    {image}
                    <div className="recommendationTextWrapper">
                        <div className="recommendationText">{this.props.text}</div>
                        <div className="infoText">
                            {this.props.info} {link}
                        </div>
                    </div>
                </div>
            );

            return <div className="recommendationItemRoot">{content}</div>;
        }
    }
}

export default RecommendationItem;
