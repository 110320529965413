import {combineReducers} from 'redux';
import appState from './appStateReducer';
import answers from './answersReducer';
import userModel from './userModelReducer';

// This allows us to treat each of these states separately in the reducers
const rootReducer = combineReducers({
    appState,
    answers,
    userModel,
    //insuranceLevelReducer,
});

export default rootReducer;
