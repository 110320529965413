import initialState from './initialState';
import {UPDATE_USERMODEL} from '../actions/userModelActions';

// This handles the 'userModel' property in the global store
// so the state varaible below is referring to that part of
// the store and not the entire store.
export default function userModelReducer(state = initialState.userModel, {type, payload}) {
    switch (type) {
        case UPDATE_USERMODEL: {
            // Creates a new state from a merge of the old one and the payload
            let newuserModel = {...state, ...payload};

            return newuserModel;
        }
        default: {
            return state;
        }
    }
}
