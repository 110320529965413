import initialState from './initialState';
import {ADD_ANSWER} from '../actions/answersActions';

// This handles the 'answer' property in the global store
// so the state varaible below is referring to that part of
// the store and not the entire store.
export default function answersReducer(state = initialState.answers, {type, payload}) {
    switch (type) {
        case ADD_ANSWER: {
            let newAnswers = [...state, payload];
            return newAnswers;
        }
        default: {
            return state;
        }
    }
}
