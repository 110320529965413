// This aggregates all individual insurance advices to an array
export default function getKidsInsuranceAdvice(userModel, level, kontor) {
    let advice = [];

    let age = (userModel.age.min + userModel.age.max) / 2;

    if (userModel.hasKids) {
        // const pbb = 52500; // prisbasbelopp
        // let value;
        // const slider_step = 16.7; // = slider's step
        // if (level < 1.5 * slider_step) {
        //     value = 10 * pbb; // 525K
        //     //   Lägst Skydd
        //     //   Extra Lågt Skydd
        // } else if (level < 2.5 * slider_step) {
        //     // Lågt Skydd
        //     value = 20 * pbb; // 1.05M
        // } else if (level < 3.5 * slider_step) {
        //     // Medelstarkt Skydd
        //     value = 30 * pbb; // 1.575M
        // } else if (level < 4.5 * slider_step) {
        //     // Starkt Skydd
        //     value = 40 * pbb; // 2.1M
        // } else {
        //     // Extra Starkt Skydd
        //     // Starkast Skydd
        //     value = 50 * pbb; // 2.625M
        // }

        // let valueText;
        // if (value >= 1000000) {
        //     valueText = value / 1000000 + ' M kr';
        // } else {
        //     valueText = value / 1000 + ' 000 kr';
        // }

        // if (userModel.hasKids > 1) {
        //     valueText += ' per barn';
        // }
        advice.push({
            key: 'kids',
            // title: 'Barnförsäkring, ' + valueText,
            title: 'Barnförsäkring',
            titleShort: 'Barn',
            info: 'Med vår barnförsäkring kan du vara trygg redan från födseln. Försäkringen gäller om ditt barn skulle drabbas av sjukdom eller olycksfall.',
            link: 'https://www.lansforsakringar.se/' + kontor + '/privat/forsakring/personforsakring/barnforsakring/',
            linkText: 'Räkna på pris här',
        });
    }

    if (age > 20 && age < 40 && userModel.hasPartner === true) {
        advice.push({
            key: 'pregnancy',
            title: 'Väntar ni barn?',
            titleShort: 'Gravid?',
            info: 'Få en trygg start och försäkra dig och ditt ofödda barn så tidigt som möjligt under graviditeten.',
            link: 'https://www.lansforsakringar.se/' + kontor + '/privat/forsakring/personforsakring/gravidforsakring/',
        });
    }

    console.log('Kid insurance:', advice);
    return advice;
}
