export const offices = [
    {
        querystring: 'alvsborg',
        general: {
            name: 'Länsförsäkringar – Älvsborg',
            phone: '0521-27 30 00',
            email: 'info04-forsakringscenter@lansforsakringar.se',
        },
        openingHours: [
            { day: 'Måndag–Torsdag', hours: '08:00–18:00' },
            { day: 'Fredag', hours: '08:00–17:00' },
            { day: 'Lördag–Söndag', hours: 'Stängt' },
        ],
    },

    {
        querystring: 'goteborg-och-bohuslan',
        general: {
            name: 'Länsförsäkringar – Göteborg och Bohuslän',
            phone: '031-63 80 00',
            email: 'erbjudande@lansforsakringar.se',
        },
        openingHours: [
            { day: 'Måndag–Torsdag', hours: '08:00–17:00' },
            { day: 'Fredag', hours: '08:00–16:00' },
            { day: 'Lördag–Söndag', hours: 'Stängt' },
        ],
    },

    {
        querystring: 'halland',
        general: {
            name: 'Länsförsäkringar – Halland',
            phone: '035-15 10 00',
            email: 'weblead@lfhalland.se',
        },
        openingHours: [
            { day: 'Fredag', hours: '08:00–16:30' },
            { day: 'Lördag–Söndag', hours: 'Stängt' },
        ],
    },
];
