import {connect} from 'react-redux';
import {incrementActiveQuestionNumber} from '../actions/appStateActions';
import {addAnswer} from '../actions/answersActions';
import {updateUserModel} from '../actions/userModelActions';
import InterviewQuestion from '../components/common/interviewquestion/InterviewQuestion';
import {questions} from '../questionsdata';

// The answers are stored in an array to support submission of multiple answers
// with a submit button in a multiple select card.
export function submitAnswer(question, answerArray) {
    return (dispatch, getState) => {
        for (let i = 0; i < answerArray.length; i++) {
            if (answerArray[i].logAnswer !== false) {
                dispatch(addAnswer(answerArray[i]));
            }
            dispatch(updateUserModel(answerArray[i].userModel));
        }

        dispatch(incrementActiveQuestionNumber(getState().userModel));
    };
}

const getCurrentQuestion = (index) => {
    let question = questions[index];
    return question;
};

const mapStateToProps = (state) => {
    return {
        activeQuestion: state.appState.questionIndex,
        question: getCurrentQuestion(state.appState.questionIndex),
        form: state.appState.form,
        userModel: state.userModel,
    };
};

const mapDispatchToProps = {
    onSubmitAnswer: submitAnswer,
};

export default connect(mapStateToProps, mapDispatchToProps)(InterviewQuestion);
