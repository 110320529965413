import { Buffer } from 'buffer';

const utils = {
    // Maps a value in the interval [lowThreshold, highThresold] to the interval
    // [minValue, maxValue], clamping it to the min and max values.
    // Note that minValue does not have to be less than maxValue. The name
    // comes from that the minValue is the value produced when the input value
    // is below the low threhsold.
    //
    // maxValue    |                ___________________
    //             |               /:
    //             |              / :
    //             |             /  :
    // minValue  __|____________/   :
    // ------------|------------:---:-------------------------->
    //               lowThreshold   :
    //                    highThreshold
    //
    blendAndClamp(minValue, maxValue, lowThreshold, value, highThreshold) {
        if (value <= lowThreshold) {
            return minValue;
        } else if (value >= highThreshold) {
            return maxValue;
        } else {
            return minValue + ((maxValue - minValue) * (value - lowThreshold)) / (highThreshold - lowThreshold);
        }
    },

    // From here: https://css-tricks.com/snippets/javascript/get-url-variables/
    // Returns the value of a query variable (or undefined)
    getQueryVariable(variableName) {
        var query = window.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0;i < vars.length;i++) {
            var pair = vars[i].split('=');
            if (pair[0] === variableName) {
                return pair[1];
            }
        }
        return undefined;
    },

    createLink(userModel = [], insuranceLevel = '', officeQs = '') {
        let jsonString = JSON.stringify(userModel);
        let base64string = Buffer.from(jsonString).toString('base64');
        let host = window.location.host;
        let search = window.location.search;
        let params = new URLSearchParams(search);
        params.set('kontor', officeQs);
        params.set('level', Math.round(insuranceLevel * 100) / 100);
        params.set('recommendations', encodeURIComponent(base64string));
        let url = host + '/?' + params.toString();

        return url
    }
};

function createEnum(values) {
    const enumObj = {};
    for (const val of values) {
        enumObj[val] = val;
    }
    return Object.freeze(enumObj);
}



export const pageEnum = createEnum(['welcome', 'form', 'done', 'result']);

export default utils;
