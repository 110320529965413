import {configureStore} from '@reduxjs/toolkit';
import rootReducer from '../reducers';
import logger from 'redux-logger';

export default function store(initialState) {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
        preloadedState: initialState,
    });
}
