import React, {Component} from 'react';
import {Route, withRouter} from 'react-router-dom';
import ContentSceneContainer from '../containers/ContentSceneContainer';

import './main.css';

/*
    High level structure of app:

    The content scene renders the content in 
    the header and the content containers 
    based on which section in the flow that is 
    active. 

    +-Root-----------------------------+
    | +--ContentScene----------------+ |
    | |                              | |
    | |            header            | |
    | |       (interviewHeader)      | |
    | |                              | |
    | |                              | |
    | +------------------------------+ |
    | |                              | |
    | |           content            | |
    | |      (interviewQuestion)     | |
    | |  (recommendationsContainer)  | |
    | |                              | |
    | |                              | |
    | +------------------------------+ |
    +----------------------------------+

*/

class Main extends Component {
    constructor(props) {
        super(props);
        props.history.listen((location, action) => {
            if (action === 'POP') {
                window.location.reload();
            }
        });
    }

    render() {
        return (
            <div className="rootContainer">
                <div className="contentContainer">
                    <Route path="/" component={ContentSceneContainer} />
                </div>
            </div>
        );
    }
}

export default withRouter(Main);
