import {connect} from 'react-redux';
import ContentScene from '../components/scenes/contentScene/ContentScene';
import {goToResultPage, incrementActiveQuestionNumber} from '../actions/appStateActions';
import {updateUserModel} from '../actions/userModelActions';
import {setInsuranceLevel} from '../actions/insuranceLevelActions';

// The answers are stored in an array to support submission of multiple answers
// with a submit button in a multiple select card.
export function nextPage() {
    return (dispatch, getState) => {
        dispatch(incrementActiveQuestionNumber(getState().userModel));
    };
}
export function resultPage(model, level) {
    return (dispatch, getState) => {
        dispatch(goToResultPage(model));
        dispatch(updateUserModel(model));
        dispatch(setInsuranceLevel(level));
    };
}

const mapStateToProps = (state) => {
    return {
        appState: state.appState,
        userModel: state.userModel,
    };
};

const mapDispatchToProps = {
    onNextPage: nextPage,
    onResult: resultPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentScene);
