// This aggregates all individual insurance advices to an array
export default function getCompanyInsuranceAdvice(userModel, level, kontor) {
    let advice = [];

    if (userModel.hasCompany) {

        const infoText =
            'Oavsett vad du bedriver för typ av verksamhet behöver du en bra företagsförsäkring. ' +
            'Vi är lokala och finns nära dig om du behöver hjälp.';

        advice.push({
            key: 'company-insurance',
            title: 'Försäkringar för företag',
            titleShort: 'Företagsförsäkring',
            info: infoText,
            link: 'https://www.lansforsakringar.se/' + kontor + '/foretag/forsakring/',
        });
    }
    console.log('Company insurance:', advice);
    return advice;
}
