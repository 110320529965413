import React, {useEffect} from 'react';
import {Buffer} from 'buffer';
import InterviewHeaderContainer from '../../../containers/InterviewHeaderContainer';
import RecommendationItem from '../../../components/common/recommendationitems/RecommendationItem';
import './contentScene.scss';
import InterviewQuestionContainer from '../../../containers/InterviewQuestionContainer';
import getInsuranceAdvise from '../../../insurances/insuranceAdvisor';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import assets from '../../../assets';
import {questions} from '../../../questionsdata';
import getOffice from '../../../insurances/getOffice';
import utils, {pageEnum} from '../../../utils';

const ContentScene = (props) => {
    let goToResultPage = props.onResult;

    useEffect(() => {
        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);
        if (urlParams.has('recommendations') && urlParams.has('level')) {
            let insuranceLevel = urlParams.get('level');
            let recommendations = urlParams.get('recommendations');
            let jsonString = Buffer.from(decodeURIComponent(recommendations), 'base64').toString();
            let model = JSON.parse(jsonString);
            goToResultPage(model, insuranceLevel);
        }
    }, [goToResultPage]);

    function getInsuranceRecommendationFromUserProfile(level, user) {
        console.log('Producing recommendation at level ' + level + ' for user', user);

        let advice = getInsuranceAdvise(user, level);
        let recommendations = [];

        for (let i = 0; i < advice.length; i++) {
            recommendations.push(
                <RecommendationItem
                    key={advice[i].key}
                    text={advice[i].title}
                    info={advice[i].info}
                    link={advice[i].link}
                    linkText={advice[i].linkText}
                />,
            );
        }

        let officeObj = getOffice();
        let officeQs = officeObj.querystring;

        let shareLink = utils.createLink(props.userModel, level, officeQs);

        return (
            <div>
                <RecommendationItem
                    key="call_us"
                    text="Kontakta oss"
                    hasButton={true}
                    officeObj={officeObj}
                    buttonText="Vill du bli kontaktad klicka här"
                    icon="callUs"
                    shareLink={shareLink}
                    userModel={props.userModel}
                />
                {recommendations}
                <RecommendationItem
                    key="gold"
                    text="Bli helkund hos oss"
                    info="När du samlar dina bank- och försäkringsaffärer får du flera fördelar. Bli helkund och du får rabatt på hushållets privata försäkringar."
                    link={'http://www.lansforsakringar.se/' + officeQs + '/privat/om-oss/erbjudanden/guldkund/'}
                    icon="gold"
                />

                <RecommendationItem
                    key="again"
                    text="Prova igen"
                    info="Vill du svara på frågorna en gång till? "
                    linkText="Börja om"
                    link={'./?kontor=' + officeQs}
                />
                <div className="footerBelowRecommendations" />
            </div>
        );
    }

    function goToNextPage() {
        props.onNextPage();
    }

    // The 'result' page shows the recommendations
    function renderWelcomePage() {
        let header, content;
        console.log('Rendering welcome page');

        var backgroundImage = assets.getUrl('img1');
        assets.prefetch(questions[0].imageName);

        header = (
            <div className="interviewHeaderRoot">
                <TransitionGroup className="transition-header-group-root">
                    <CSSTransition key={'welcome'} timeout={800} classNames="header-animation">
                        <img className="background-image right-aligned" src={backgroundImage} alt="" />
                    </CSSTransition>
                </TransitionGroup>

                <div className="header-container-alone">
                    <div className="bigTitle">
                        <div className="bigTitleText">
                            Är du <span className="bigTitleAccentText">rätt</span> försäkrad?
                        </div>
                    </div>
                </div>
            </div>
        );

        content = (
            <div className="verticalStretchedStack">
                <div className="pageInfoText">
                    Med vår digitala guide kan du enkelt se vilka försäkringar som matchar just din livssituation.
                </div>
                <div className="nextButton" onClick={() => goToNextPage()}>
                    <div className="nextButtonText">Kom igång!</div>
                </div>
            </div>
        );

        return {header, content};
    }

    // The 'form' holds pages where the quesitons are shown
    function renderFormPage() {
        let header, content;

        header = <InterviewHeaderContainer />;

        content = <InterviewQuestionContainer />;

        return {header, content};
    }

    // The 'result' page shows the recommendations
    function renderResultPage() {
        let header, content;

        let appState = props.appState;

        header = <InterviewHeaderContainer />;

        let recommendationContent = getInsuranceRecommendationFromUserProfile(appState.insuranceLevel.value, props.userModel);

        content = <div className="recommendationsContainer">{recommendationContent}</div>;

        return {header, content};
    }

    let appState = props.appState;

    let page = {};

    switch (appState.currentPage) {
        case pageEnum.welcome:
            page = renderWelcomePage();
            break;
        case pageEnum.form:
            page = renderFormPage();
            break;
        case pageEnum.result:
            page = renderResultPage();
            break;
        default:
            console.error('Current page is something unknown!');
    }

    return (
        <div className="contentSceneRoot">
            {page.header}
            {page.content}
        </div>
    );
};

export default ContentScene;
