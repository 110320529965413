import React, {Component} from 'react';
import './progressbar.scss';

class ProgressBar extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        let percentage;
        if (this.props.maxValue === 0) {
            percentage = 0;
        } else {
            percentage = this.props.value / this.props.maxValue;
        }

        return (
            <div className="progressBarRoot">
                <div className="valueBarContainer">
                    <div className="valueBar" style={{width: percentage * 100 + '%'}} />
                </div>
            </div>
        );
    }
}

export default ProgressBar;
