// This aggregates all individual insurance advices to an array
export default function getTravelInsuranceAdvice(userModel, level, kontor) {
    let advice = [];

    if (level > 60) {
        let person = 'du ';
        if (userModel.hasKids) {
            person = 'du och din familj ';
        }

        const infoText =
            'I din hemförsäkring ingår ett reseskydd som ger dig en bra grund när ' +
            person +
            'är ute och reser i världen. Vill du ha ett mer omfattande reseskydd, eller om du ska vara ' +
            'utomlands en längre tid kan du behöva teckna en tilläggsförsäkring.';

        advice.push({
            key: 'travel-insurance',
            title: 'Utökat reseskydd',
            titleShort: 'Reseskydd',
            info: infoText,
            link: 'https://www.lansforsakringar.se/' + kontor + '/privat/forsakring/reseforsakring/',
        });
    }
    console.log('Travel insurance:', advice);
    return advice;
}
